import React from 'react';
import { createRoot } from 'react-dom/client';
import moment from 'moment-timezone';
import App from './features/app';
import * as serviceWorker from './serviceWorker';

import './index.css';
import 'swiper/swiper.min.css';
import 'swiper/css/autoplay';
import 'swiper/css/effect-fade';
import 'swiper/css/virtual';

moment.tz.setDefault('Asia/Bangkok');

const rootEl = document.getElementById('root');

createRoot(rootEl!).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
