import React from 'react';
import classNames from 'classnames';

import styles from './index.module.css';

const Loader: React.FC<{ className?: string }> = (props) => {
  return (
    <div className={classNames([styles.spinner, props.className])}>
      <div className={styles.dot1} />
      <div className={styles.dot2} />
    </div>
  );
};

export default Loader;
