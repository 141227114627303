import React, { Suspense, lazy } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import Loader from './components/Loader';

const renderPageComponent = (Comp: React.FC) => (
  <Suspense
    fallback={(
      <div className="flex items-center justify-center w-screen h-screen">
        <Loader className="mx-auto" />
      </div>
    )}
  >
    <Comp />
  </Suspense>
);

type IRoute = {
  path: string,
  component: any,
}

const routes: IRoute[] = [
  {
    path: '/room/:id/wish',
    component: renderPageComponent(
      lazy(() => import(/* webpackChunkName: "wish.page" */'../../pages/wish')),
    ),
  },
  {
    path: '/room/:id/board',
    component: renderPageComponent(
      lazy(() => import(/* webpackChunkName: "room-board.page" */'../../pages/board')),
    ),
  },
  {
    path: '/room/:id/qr',
    component: renderPageComponent(
      lazy(() => import(/* webpackChunkName: "room-qr.page" */'../../pages/room-qr')),
    ),
  },
];

const AppRoutes: React.FC = () => {
  return (
    <BrowserRouter>
      <Routes>
        {routes.map(route => (
          <Route key={route.path} path={route.path} element={route.component} />
        ))}
      </Routes>
    </BrowserRouter>
  );
};

export default AppRoutes;
